<template>
    <div>
        <h3 class="mb-2">
            {{ label }}
        </h3>
        <v-text-field
            v-if="showSearch"
            v-model="search"
            :placeholder="`Filter ${label.toLowerCase()}...`"
            class="styled-field mb-3"
            clearable />

        <v-checkbox
            v-for="item in displayedItems"
            :key="item.value || item"
            v-model="selections"
            :value="item"
            class="styled-checkbox mb-2">
            <template #label>
                <div>
                    <div>{{ fbToMeta(item.label || item) }}</div>
                    <div
                        v-if="item.subLabel"
                        class="sub-label">
                        {{ item.subLabel }}
                    </div>
                </div>
            </template>
        </v-checkbox>

        <a
            v-if="moreCount > 0"
            class="filter-link"
            href="#"
            @click.prevent="showMore = !showMore">
            {{ showMore ? 'Show less' : `Show ${moreCount} more` }}
        </a>
    </div>
</template>

<script>
import { fbToMeta } from '@/helpers/fbToMeta';

export default {
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        displayCount: {
            type: Number,
            default: 5,
        },
        showSearch: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            search: '',
            showMore: false,
            selections: [],
            fbToMeta
        };
    },
    computed: {
        displayedItems() {

            if (this.search) {
                return this.items.filter(item => (
                    (item.label || item).toLowerCase().includes(this.search.toLowerCase())
                ));
            }

            if (!this.showMore && (this.items.length > this.displayCount)) {
                return this.items.slice(0, this.displayCount);
            }

            return this.items;
        },
        moreCount() {
            return this.items.length - this.displayCount;
        }
    },
    watch: {
        value: {
            handler(value) {
                this.selections = value;
            },
            immediate: true,
        },
        selections(value) {
            this.$emit('input', value);
        }
    }
};
</script>

<style lang="scss" scoped>
.selected-items {
    border-bottom: 1px dashed $loblolly;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
}
.sub-label {
    font-size: 8px;
    line-height: 10px;
    color: $gray;
    font-style: italic;
}
</style>