<template>
    <div
        class="playbook ads-library-playbooks-tab">
        <playbooks-modal
            v-model="playbooksModalActive"
            :playbook="selectedPlaybook"
            :loading="isLoading"
            @select="onPlaybookSelection"
            @show-slideout="openSlideout" />
        <playbooks-slideout
            v-model="showSlideout"
            :playbook="selectedPlaybook"
            :facebook-selected="isFacebookSelected"
            :tiktok-selected="isTiktokSelected"
            :snapchat-selected="isSnapchatSelected"
            :pinterest-selected="isPinterestSelected" />
        <dealer-product-update-dialog
            v-model="productDialogActive"
            :dealer="currentDealer"
            :product-ids="selectedPlay ? [ selectedPlay.product_id ] : []"
            :play-id="selectedPlay ? selectedPlay.id : null" />

        <playbook-preview-panel
            v-model="playbookPreviewPanelOpen"
            :playbook="playbookToPreview" />


        <!-- Exit dialog start -->
        <styled-dialog
            v-model="exitDialogOpen"
            :width="600">
            <template #heading>
                <h3>Are you sure you want to exit?</h3>
            </template>

            <p class="mb-5">
                Since you've selected an advertising product
                we highly recommend selecting a playbook to deploy now.
                If you're not ready to advertise you can change
                your subscription on the dealer page. If you do exit
                you can get back to this page from the navigation
                under "Manage Playbooks".  Are you sure you
                want to exit?
            </p>

            <div class="text-xs-center mb-4">
                <styled-button
                    @click="$router.push({
                        name: 'dealer'
                    })">
                    Yes, Exit to Dashboard
                </styled-button>
            </div>

            <a
                href="#"
                class="red--text"
                @click.prevent="exitDialogOpen = false">
                Cancel
            </a>
        </styled-dialog>
        <!-- Exit dialog end -->

        <onboarding-header
            v-if="!!$route.query.onboarding"
            :dealer="currentDealer"
            :products="dealerProducts"
            show-product
            show-play
            @complete="onProductUpdateComplete()">
            <template #actions>
                <action-button
                    icon="indicator-error"
                    position="left"
                    light
                    normal-text
                    @click="exitDialogOpen = true">
                    Exit
                </action-button>
            </template>
        </onboarding-header>

        <div
            v-if="loading"
            class="loading-container">
            <loader />
        </div>

        <template v-else>
            <v-alert
                :value="error"
                class="mb-4">
                {{ error }}
            </v-alert>

            <div class="play-catalog layout">
                <div class="flex play-catalog__left">
                    <div class="play-filters">
                        <div class="filter-wrapper">
                            <playbook-filter
                                v-model="selectedChannels"
                                :items="channelOptions"
                                label="Channel"
                                @input="setSelections" />
                        </div>
                        <!-- <div class="filter-wrapper">
                            <playbook-filter
                                v-model="selectedVerticals"
                                :items="verticalOptions"
                                label="Vertical"
                                @input="setSelections" />
                        </div> -->
                        <div class="filter-wrapper">
                            <playbook-filter
                                v-model="selectedType"
                                :items="typeOptions"
                                label="Type"
                                @input="setSelections" />
                        </div>
                        <div class="filter-wrapper">
                            <playbook-filter
                                v-model="selectedBrand"
                                :items="brandOptions"
                                label="Brand"
                                show-search
                                @input="setSelections" />
                        </div>
                        <div class="filter-wrapper">
                            <playbook-filter
                                v-model="selectedFocus"
                                :items="focusOptions"
                                label="Focus"
                                show-search
                                @input="setSelections" />
                        </div>
                    </div>
                </div>

                <div
                    class="play-results flex play-catalog__right"
                    :class="{
                        'no-results': noResults
                    }">
                    <div class="play-controls">
                        <div class="play-controls__left">
                            <div class="play-controls-search">
                                <input
                                    v-model="searchTerms"
                                    type="text"
                                    placeholder="Search Playbooks...">
                            </div>
                            <div v-if="!isTablet">
                                <toggle-view v-model="view" />
                            </div>
                        </div>
                        <div class="play-controls__right">
                            <span class="results">{{ filteredPlaybooks.length }} results</span>
                            <div class="playbooks__sort">
                                <span>Sort:</span>
                                <v-select
                                    v-model="sort"
                                    :items="sortItems"
                                    class="adslib__select playbooks__select-sort" />
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="selectedTags.length"
                        class="playbooks-tags">
                        <div
                            class="playbooks-tagname">
                            Filtered by:
                        </div>
                        <div class="playbooks-applied">
                            <div
                                v-for="(tag,index) in selectedTags"
                                :key="index"
                                class="playbooks-single">
                                <span>{{ fbToMeta(tag.label || tag) }}</span>
                                <div @click="removeTag(tag)">
                                    <icon
                                        name="close"
                                        color="#8f9ea6"
                                        size="10" />
                                </div>
                            </div>
                            <div
                                class="playbooks-clear"
                                @click="clearAll">
                                Clear all
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="hasVerticalPlaybooks && searchTerms == '' && selectedTags.length == 0"
                        class="play-carousel">
                        <h3>Featured</h3>
                        <carousel
                            :per-page="1"
                            :mouse-drag="true"
                            :navigation-enabled="true"
                            :pagination-enabled="false"
                            navigation-next-label="More"
                            navigation-prev-label="Back">
                            <slide
                                v-for="playbook in featuredPlaybooks"
                                :key="playbook.id"
                                class="slide mr-4 mt-2">
                                <playbook-card
                                    :value="playbook"
                                    :loading="loadingPlaybook == playbook.id"
                                    @select="onPlaybookSelection"
                                    @preview="onPlaybookPreview" />
                            </slide>
                        </carousel>
                    </div>

                    <div v-if="noResults">
                        <no-results />
                    </div>

                    <!-- List view start -->
                    <div
                        v-if="view == 'list'"
                        class="play-results-list">
                        <playbook-list
                            v-for="playbook in filteredPlaybooks"
                            :key="playbook.id"
                            :value="playbook"
                            :loading="loadingPlaybook == playbook.id"
                            class="mb-4"
                            @select="onPlaybookSelection"
                            @preview="onPlaybookPreview" />
                        <div
                            v-if="!noResults"
                            class="layout get-in-touch-row">
                            <div class="flex xs9 offset-xs3 layout align-center">
                                <div class="flex text-xs-center">
                                    <h2 class="mb-3 font-weight-bold">
                                        Got a playbook strategy that you’d like us to build?
                                    </h2>
                                    <styled-button
                                        class="get-in-touch-button"
                                        @click="onRequestNewPlaybook">
                                        LET'S CHAT
                                    </styled-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- List view end -->

                    <!-- Grid view start -->
                    <div
                        v-else-if="view == 'grid'"
                        class="play-results-grid">
                        <playbook-grid
                            v-for="playbook in filteredPlaybooks"
                            :key="playbook.id"
                            :value="playbook"
                            :loading="loadingPlaybook == playbook.id"
                            @select="onPlaybookSelection"
                            @preview="onPlaybookPreview" />
                        <div
                            v-if="!noResults"
                            class="play-callout-grid">
                            <div class="">
                                <div class="wrap-content fill-height gray-icons">
                                    <h1 class="primary--text text-center py-4 px-3 font-weight-bold">
                                        Got a playbook strategy that you’d like us to build?
                                    </h1>
                                    <div class="px-3 form-button text-xs-center">
                                        <v-btn
                                            flat
                                            large
                                            color="white"
                                            @click.native="onRequestNewPlaybook">
                                            Let's Chat
                                        </v-btn>
                                    </div>
                                    <div class="">
                                        <v-img
                                            src="/img/plays/hero/get-in-touch.png"
                                            height="400px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Grid view end -->
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { PRODUCT_ADVERTISING, PRODUCT_ADVERTISING_PLUS, VERTICAL_AUTOMOTIVE } from '@/helpers/globals';
import { mapState, mapGetters, mapActions } from 'vuex';
import { debounce, sortBy } from 'lodash';
import ActionButton from '@/components/globals/ActionButton';
import StyledDialog from '@/components/globals/StyledDialog';
import OnboardingHeader from '@/components/globals/OnboardingHeader';
import Loader from '@/components/globals/Loader';
import ToggleView from '@/components/globals/ToggleView';
import Icon from '@/components/globals/Icon';
import StyledButton from '@/components/globals/StyledButton';
import DealerProductUpdateDialog from '@/components/globals/DealerProductUpdateDialog';
import PlaybooksModal from '@/components/playbooks/PlaybooksModal';
import PlaybooksSlideout from '@/components/playbooks/PlaybooksSlideout';
import PlaybookPreviewPanel from '@/components/playbooks/PlaybookPreviewPanel';
import PlaybookGrid from '@/components/playbooks/PlaybookGrid';
import PlaybookList from '@/components/playbooks/PlaybookList';
import PlaybookCard from '@/components/playbooks/PlaybookCard';
import PlaybookFilter from '@/components/ads-library/PlaybookFilter';
import NoResults from '@/components/playbooks/NoResults';
import { fbToMeta } from '@/helpers/fbToMeta';
import { Carousel, Slide } from 'vue-carousel';
import Fuse from 'fuse.js';

export default {
    name: 'Playbooks',
    title: 'Playbooks',
    components: {
        NoResults,
        PlaybookFilter,
        ActionButton,
        StyledDialog,
        OnboardingHeader,
        DealerProductUpdateDialog,
        PlaybooksModal,
        PlaybooksSlideout,
        Icon,
        PlaybookGrid,
        PlaybookList,
        PlaybookCard,
        StyledButton,
        Loader,
        ToggleView,
        Carousel,
        Slide,
        PlaybookPreviewPanel
    },
    data() {
        return {
            exitDialogOpen: false,
            channelOptions: [],
            selectedChannels: [],
            brandOptions: [],
            selectedBrand: [],
            focusOptions: [],
            selectedFocus: [],
            selectedPlaybook: {},
            typeOptions: [],
            selectedType: [],
            selectedVerticals: [],
            isTablet: false,
            isLoading: false,
            view: 'grid',
            dealerPlays: [],
            selectedPlay: null,
            loading: true,
            loadingPlaybook: null,
            productDialogActive: false,
            productAgreementVerified: false,
            loadingProductRelationships: false,
            error: null,
            hasInit: false,
            searchTerms: '',
            playbooksRaw: [],
            playbookPreviewPanelOpen: false,
            playbookToPreview: null,
            playbooksModalActive: false,
            showSlideout: false,
            isTiktokSelected: false,
            isSnapchatSelected: false,
            isFacebookSelected: false,
            isPinterestSelected: false,
            verticals: [],
            VERTICAL_AUTOMOTIVE,
            sort: 'Newest',
            sortItems: ['Newest', 'Oldest', 'Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)'],
            selectedTags: [],
            fbToMeta
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            currentDealerId: (state) => state.dealer.currentDealerId
        }),
        ...mapGetters([
            'onboardingIneligibleProducts',
            'onboardingIneligibleProductConflict',
            'activeDealerOnboardings',
            'dealerProducts',
            'dealerProductIds',
            'dealerHasAdProduct'
        ]),
        noResults() {
            return  !this.filteredPlaybooks.length;
        },
        dealerHasAdsPlus() {
            return this.dealerProductIds.includes(PRODUCT_ADVERTISING_PLUS);
        },
        featuredPlaybooks() {
            return this.playbooks.filter(playbook => playbook.marketing_assets?.featured);
        },
        hasVerticalPlaybooks() {
            return this.playbooks.some(playbook => {
                // If there's no verticals defined we can display
                // these for all dealers
                if (!playbook.marketing_assets?.verticals?.length) {
                    return true;
                }

                return playbook.marketing_assets?.verticals.includes(this.currentDealer.vertical_id);
            });
        },
        verticalOptions() {
            return this.verticals.map(vertical => {
                return {
                    label: vertical.display_name,
                    subLabel: vertical.id !== VERTICAL_AUTOMOTIVE ? 'Coming Soon' : '',
                    value: vertical.id,
                };
            });
        },
        filteredPlaybooks() {

            let filtersPlaybooks = [...this.playbooks];

            if (this.searchTerms.length) {
                const fuse = new Fuse(filtersPlaybooks, {
                    keys: ['display_name', 'marketing_assets.description'],
                    threshold: 0.3,
                    ignoreLocation: true,
                    useExtendedSearch: true,
                });
                const searchResults = fuse.search(this.searchTerms.trim());
                filtersPlaybooks = searchResults.map(searchResults => searchResults.item);
            }

            // verticals are working with number values, not 'names' as other filters...
            const filteredVerticals = [];
            this.selectedVerticals.forEach(vertical =>{
                filteredVerticals.push(vertical.value);
            });

            const filters = {
                channels: this.selectedChannels,
                brands: this.selectedBrand,
                focus: this.selectedFocus,
                types: this.selectedType,
                verticals: filteredVerticals,
            };

            for (let filter in filters) {
                filtersPlaybooks = filtersPlaybooks.filter(playbook => {
                    const filterValues = filters[filter];
                    if (filterValues.length) {
                        const values = playbook.marketing_assets[filter];
                        if (values instanceof Array) {
                            return values.some(value => (
                                filterValues.includes(value)
                            ));
                        }
                    }
                    return true;
                });
            }

            if (this.sort == 'Newest') {
                const newestPlaybooks = filtersPlaybooks.sort((a,b) => new Date(b.created_at.date) - new Date(a.created_at.date));
                return newestPlaybooks;
            } else if (this.sort == 'Oldest') {
                const oldestPlaybooks = filtersPlaybooks.sort((a,b) => new Date(a.created_at.date) - new Date(b.created_at.date));
                return oldestPlaybooks;
            } else if (this.sort == 'Updated') {
                const updatedPlaybooks = filtersPlaybooks.sort((a,b) => new Date(b.updated_at.date) - new Date(a.updated_at.date));
                return updatedPlaybooks;
            } else if (this.sort == 'Alphabetical (A-Z)') {
                const ascendingPlaybooks = filtersPlaybooks.sort((a, b) => (a.display_name > b.display_name) ? 1 : -1);
                return ascendingPlaybooks;
            } else if (this.sort == 'Alphabetical (Z-A)') {
                const descendingPlaybooks = filtersPlaybooks.sort((a, b) => (a.display_name > b.display_name) ? -1 : 1);
                return descendingPlaybooks;
            }

            return filtersPlaybooks;
        },
        playbooks() {

            const checkPlaybookStatus = (playbook) => {
                if (playbook.plays?.[0]?.product_id == PRODUCT_ADVERTISING_PLUS) {
                    return this.dealerHasAdsPlus;
                }

                return this.dealerHasAdProduct;
            };

            const formatPlaybook = (playbook) => {
                playbook.order = 0;
                playbook.onboarding = false;
                playbook.deployed = false;
                playbook.error = null;
                playbook.valid = checkPlaybookStatus(playbook);

                playbook.facebookOnly = playbook.plays.some(play => {
                    return play.dependencies.includes('facebook_automotive_catalog');
                });

                // Order advertising plays higher for dealers on that product
                if (this.dealerProductIds.includes(PRODUCT_ADVERTISING) &&
                    playbook.plays?.[0]?.product_id == PRODUCT_ADVERTISING) {
                    playbook.order = -100;
                }

                const existingOnboarding = this.activeDealerOnboardings.find(onboarding => onboarding.play_id === playbook.plays?.[0]?.id);
                if (existingOnboarding) {
                    playbook.onboarding = existingOnboarding.id;
                }

                else if (this.dealerPlays.some(dealerPlay => dealerPlay.play_id === playbook.plays?.[0]?.id)) {
                    playbook.deployed = true;
                    playbook.error = 'Currently Deployed';
                }

                // Order the agency's plays the highest
                if (playbook.agency_id == this.currentDealer.agency_id) {
                    playbook.order = -200;
                }

                // adding all channels to marketing assets if we don't have any
                if (playbook.marketing_assets.channels == undefined) {
                    playbook.marketing_assets.channels = [
                        'Facebook',
                        'Snapchat',
                        'TikTok',
                        'Pinterest'
                    ];
                }

                return playbook;
            };

            const playbooks = this.playbooksRaw
                            .filter(playbook => playbook.marketing_assets !== null)
                            .filter(playbook => (
                                playbook.agency_id == null || playbook.agency_id == this.currentDealer.agency_id
                            ))
                            .map(formatPlaybook);

            return sortBy(playbooks, ['order', 'display_name']);
        },
        focusQuery() {
            return this.$route.query.focus?.replace('+', ' ') ?? '';
        },
    },
    watch: {
        isTablet() {
            if (this.view == 'list') {
                this.view == 'grid';
            }
        },
        playbooks() {
            let channelOptions = [];
            let brandOptions = [];
            let focusOptions = [];
            let typeOptions = [];

            this.playbooks.forEach(playbook => {
                if (playbook.marketing_assets.channels instanceof Array) {
                    channelOptions = [
                        ...channelOptions,
                        ...playbook.marketing_assets.channels
                    ];
                }
                if (playbook.marketing_assets.brands instanceof Array) {
                    brandOptions = [
                        ...brandOptions,
                        ...playbook.marketing_assets.brands
                    ];
                }
                if (playbook.marketing_assets.focus instanceof Array) {
                    focusOptions = [
                        ...focusOptions,
                        ...playbook.marketing_assets.focus
                    ];
                }
                if (playbook.marketing_assets.types instanceof Array) {
                    typeOptions = [
                        ...typeOptions,
                        ...playbook.marketing_assets.types
                    ];
                }
            });

            this.channelOptions = this.sortByAlpha([...new Set(channelOptions)]);
            this.brandOptions = this.sortByAlpha([...new Set(brandOptions)]);
            this.focusOptions = this.sortByAlpha([...new Set(focusOptions)]);
            this.typeOptions = this.sortByAlpha([...new Set(typeOptions)]);
        }
    },
    async mounted() {

        // Re-init with each activation
        // @todo revisit this - the problem here was that the
        // product changes were re-computing
        await this.init();

        this.handleWindowSize();

        this.onResize = debounce(() => {
            this.handleWindowSize();
        }, 300);
        window.addEventListener('resize', this.onResize.bind(this));
    },
    deactivated() {
        window.removeEventListener('resize', this.onResize.bind(this));
    },
    methods: {
        clearAll() {
            this.selectedChannels = [];
            this.selectedBrand = [];
            this.selectedFocus = [];
            this.selectedType = [];
            this.selectedVerticals = [];
            this.searchTerms = '';
        },
        removeTag(tag) {
            // need to check every array, because filters are separated...
            this.removeTagFromArray(tag, this.selectedTags);
            this.removeTagFromArray(tag, this.selectedChannels);
            this.removeTagFromArray(tag, this.selectedBrand);
            this.removeTagFromArray(tag, this.selectedFocus);
            this.removeTagFromArray(tag, this.selectedType);
            this.removeTagFromArray(tag, this.selectedVerticals);
        },
        removeTagFromArray(tag, array) {
            const isIncluded = array.includes(tag);
            if (isIncluded) {
                const index = array.indexOf(tag);
                array.splice(index, 1);
            }
        },
        setSelections(values) {
            // set selected values
            values.forEach(v => {
                const isIncluded = this.selectedTags.includes(v);
                if (!isIncluded) {
                    this.selectedTags.push(v);
                }
            });
            // remove some if needed
            this.selectedTags.forEach(s => {
                const allValues = [...this.selectedChannels, ...this.selectedBrand, ...this.selectedFocus, ...this.selectedType, ...this.selectedVerticals];
                const isIncluded = allValues.includes(s);
                if (!isIncluded) {
                    const index = this.selectedTags.indexOf(s);
                    this.selectedTags.splice(index, 1);
                }
            });
        },
        sortByAlpha(arr) {
            arr.sort((a, b) => {
                if (a < b)
                    return -1;
                if (a > b)
                    return 1;
                return 0;
            });

            return arr;
        },
        ...mapActions([
            'updateCurrentDealer'
        ]),
        onPlaybookPreview(playbook) {
            this.playbookToPreview = playbook;
            this.playbookPreviewPanelOpen = true;
        },
        async init() {
            this.loading = true;
            await this.updateCurrentDealer();

            if (this.focusQuery) {
                this.selectedFocus = [this.focusQuery];
                this.setSelections([this.focusQuery]);
            }

            await Promise.all([
                this.getPlaybooks(),
                this.getDealerPlays(),
                this.getVerticals(),
            ]);

            this.loading = false;
            this.hasInit = true;
        },
        onRequestNewPlaybook() {
            this.$store.dispatch('createTicket', {
                subject: 'New Playbook Request',
                type: 'Other'
            });
        },
        handleWindowSize() {
            this.isTablet = (window.innerWidth < 960);
        },
        async getPlaybooks() {
            try {
                const response = await this.$apiRepository.getPlaybooks();
                this.playbooksRaw = response.data.data;
            } catch (error) {
                console.error('Error loading dealer plays', error);
                this.error = 'Error loading plays';
            }
        },
        async getDealerPlays() {
            try {
                const response = await this.$apiRepository.getDealerPlays(this.currentDealerId);
                this.dealerPlays = response.data.data;
            } catch (error) {
                console.error('Error loading dealer plays', error);
                this.error = 'Error loading dealer plays';
            }
        },
        async getVerticals() {
            try {
                const response = await this.$apiRepository.getVerticals();
                this.verticals = response.data.data;
            } catch (error) {
                console.error('Error loading verticals', error);
                this.error = 'Error loading verticals';
            }
        },
        async onPlaybookSelection(playbook, activationFromModal) {
            this.selectedPlaybook = playbook;
            this.selectedPlay = playbook.plays?.[0];

            if (activationFromModal) {
                // If the play isn't valid we need the user to add
                // the appropriate product then continue
                if (!playbook.valid) {
                    this.productDialogActive = true;
                    return;
                }

                this.loadingPlaybook = playbook.id;
                this.isLoading = true;

                // If the play has an existing onboarding go to it
                if (playbook.onboarding) {
                    await this.$router.pushAsync({
                        name: 'dealer-onboarding',
                        params: {
                            onboarding_id: playbook.onboarding
                        }
                    });
                }
                // Otherwise let's start a new onboarding for the play
                else {
                    // If onboarding passed product IDs in the URL use them, otherwise fall
                    // back to the play's product ID
                    const productIds = this.$route.query?.product_ids || [ playbook.plays?.[0]?.product_id ];

                    await this.$store.dispatch('startNewOnboarding', {
                        productIds,
                        dealerId: this.currentDealerId,
                        playId: playbook.plays?.[0]?.id
                    });
                }
            } else {
                this.playbooksModalActive = true;
            }

            this.isLoading = false;
            this.loadingPlaybook = null;
            this.selectedPlay = null;
        },
        openSlideout(channels) {
            channels.has('facebook')  ? this.isFacebookSelected  = true : this.isFacebookSelected  = false;
            channels.has('snapchat')  ? this.isSnapchatSelected  = true : this.isSnapchatSelected  = false;
            channels.has('tiktok')    ? this.isTiktokSelected    = true : this.isTiktokSelected    = false;
            channels.has('pinterest') ? this.isPinterestSelected = true : this.isPinterestSelected = false;
            this.showSlideout = true;
        },
        onProductUpdateComplete() {
            this.selectedPlay = null;
        }
    },
    beforeRouteEnter(_, from, next) {
        if (from.name == 'onboarding-complete') {
            next(vm => { vm.$router.go() });
        } else {
            next();
        }
    },
};
</script>

<style lang="scss" scoped>

.filters {
    margin-left: auto;
    display: flex;
    align-items: center;
    & > * {
        margin-right: 1.5rem;
        &:last-child {
            margin-right: 0;
        }
    }
}
.filter-wrapper{
    margin-bottom: 2rem;
}
.play-header {
    margin: 3rem 4rem 1rem;
}

.play-header-onboarding {
    margin: 8rem 4rem 4rem;
}

.play-catalog {
    .play-results-grid {
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        background: #F7F8FC;
    }
    &__left{
        width: 20%;
        padding: 15px 20px;
        background: white;
    }
    &__right{
        width: 80%;
    }
}

.play-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:20px 30px;
    padding-bottom: 0;
    background: #F7F8FC;
    &__left{
        display: flex;
        min-width: 50%;
        align-items: center;
    }
    &__right{
        display: flex;
        align-items: center;
    }
}

.play-controls-search {
    position: relative;
    padding-right: 4%;
     min-width: 80%;
    input{
        width: 100%;
        background: white;
        border: 1px solid #DEDEDE;
        border-radius: 5px;
        line-height: 38px;
        outline: none;
        padding-left: 55px;
    }
    &:before{
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        left:18px;
        top: calc(50% - 9px);
        background-image: url('/img/search.svg');
        pointer-events: none;
    }
}

.play-callout-grid {
    display: flex;
    width: calc(33.333% - 30px);
    margin: 15px;
}

.get-in-touch-row {
    box-shadow: 0 0 10px rgba($black, 0.1);
    background: $white;
    min-height: 190px;
    background-image: url('/img/plays/hero/get-in-touch-full.png');
    background-position: 0 0;
    background-size: contain;
    background-repeat: no-repeat;
    h2 {
        font-size: 1.8rem;
    }
}
.get-in-touch-button {
    font-size: 18px;
    font-weight: bold;
}

.play-carousel {
    background: #E8ECEE;
    padding: 2rem 0;
    margin-top: 20px;
    & > h3 {
        margin: 0 4rem 1rem;
    }
}
.more{
    text-align: right;
    // padding-top:13px;
    padding-right: 15px;
    a {
        color: #2693c9;
    }
}
.playbooks__sort{
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-left:20px;
    border-left: 1px solid #DEE3E6;
}
.playbooks__select-sort{
    width: 100px;
}
.playbooks-tags{
    background: #F7F8FC;
    padding: 0 15px 15px 30px;
    border-bottom: 1px solid #D5DBDE;
}
.playbooks-tagname{
    color: #00A4EB;
    margin-bottom: 5px;
}
.playbooks-applied{
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}
.playbooks-single{
    display: flex;
    align-items: center;
    background: #D7F0FB;
    border-radius: 25px;
    padding: 5px 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    span{
        margin-right: 15px;
    }
    div{
        display: flex;
        align-items: center;
        margin-top:2px;
        cursor: pointer;
    }
}
.playbooks-clear{
    color: #00A4EB;
    text-decoration: underline;
    cursor: pointer;
}
</style>

<style lang="scss">
.play-carousel {
    .VueCarousel {
        margin: 15px 30px;
        .VueCarousel-wrapper {
            overflow: visible;

        }
        .VueCarousel-inner {
            margin-right: 500px;
            margin-bottom: 2rem;
        }
        .VueCarousel-navigation {
            position: relative;
            padding-top: 20px;
            margin-right: 0;
            button {
                position: absolute;
                bottom:0;
                color: #2693c9!important;
                height:35px;
                transition: opacity 0.3s ease-in-out;
            }

            .VueCarousel-navigation--disabled {
                opacity: 0;
                color: #000!important;
                filter: invert(0%) sepia(94%) saturate(22%) hue-rotate(49deg) brightness(102%) contrast(106%);
            }

            .VueCarousel-navigation-prev {
                left:70px!important;
            }

            .VueCarousel-navigation-prev:before {
                content: url('/img/chevron-left.svg');
                padding-right: 10px;
                position: absolute;
                left: -20px;
                filter: invert(58%) sepia(79%) saturate(653%) hue-rotate(175deg) brightness(107%) contrast(94%);
            }

            .VueCarousel-navigation-next:after {
                content: url('/img/chevron-right.svg');
                padding-left: 10px;
                position: absolute;
                right: -20px;
                filter: invert(58%) sepia(79%) saturate(653%) hue-rotate(175deg) brightness(107%) contrast(94%);
            }

            .VueCarousel-navigation-next {
                right:70px!important;
            }
        }

        .VueCarousel-dot--active {
            background-color: #2693c9!important;
        }
    }
}

.loading-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

// TAB SPECIFIC STYLES
.ads-library-playbooks-tab{
    .play-results-list{
        padding: 30px;
        background: #F7F8FC;
    }
    .play-results-grid{
        .play{
            margin-top: 30px;
            position: relative;
            &:after{
                content:"";
                position: absolute;
                width: calc(100% - 14px);
                left:7px;
                background: white;
                border-top:1px solid #D8DEE0;
                border-left:1px solid #D8DEE0;
                border-right:1px solid #D8DEE0;
                bottom: calc(100% + 1px);
                height: 7px;
            }
            &:before{
                content:"";
                position: absolute;
                width: calc(100% - 28px);
                left:14px;
                background: white;
                border-top:1px solid #D8DEE0;
                border-left:1px solid #D8DEE0;
                border-right:1px solid #D8DEE0;
                bottom: calc(100% + 8px);
                height: 7px;
            }
            .triangle-image{
                top:0;
                left:0;
                width: 100px;
                height: 100px;
                img{
                    margin-left:12px;
                    margin-top:12px;
                }
            }
        }
    }
    .play{
        box-shadow: none;
        border: 1px solid #D8DEE0;
        .play-image{
            margin: 0 0 15px 0;
        }
        .play-channels{
            margin:0;
            padding: 5px 15px 15px 20px;
        }
        a{
            color: #03A2EA;
        }
    }
    .play .play-title,
    .play-title{
        font-size: 18px;
        color: #03A2EA;
    }
    .play-results{
        overflow: hidden;
    }
    .VueCarousel{
        .image-wrapper{
            width: 40% !important;
        }
        .play-content {
            width: 60% !important;
        }
        .VueCarousel-navigation{
            transform: translateY(-15px);

        }
    }
    .play-carousel{
        padding: 15px 0;
        h3{
            margin: 5px 30px;
        }
    }
    .play-filters{
        padding-left: 20px;
        h3{
            color: #03A4EB;
            font-size: 14px;
        }
    }
    .toggle-view{
        margin-right: 15px;
        display: flex;
        button:first-child{
            margin-right: 15px;
        }
    }
    .filter-link{
        margin-left: 45px;
        color: #03A2EA;
        position: relative;
        &:after{
            content: "";
            position: absolute;
            top: 6px;
            left: -15px;
            width: 10px;
            height: 2px;
            background: #03A2EA;
            transform: rotate(-45deg);
        }
        &:before{
            content: "";
            position: absolute;
            top: 12px;
            left: -15px;
            width: 10px;
            height: 2px;
            background: #03A2EA;
            transform: rotate(45deg);
        }
    }
    .results{
        color: #777777;
    }
}
</style>
